import Link from "gatsby-link";
import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { A, H2, H3, H4 } from "@upsolve/ui";
import { graphql } from "gatsby";
import { kebabCase, orderBy, startCase } from "lodash";
import { EVENTS } from "@upsolve/shared";

import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import breadcrumbToJSONLD from "../components/SEO/breadcrumbToJSONLD";
import { Ampify } from "../components/AMP/AmpContext";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "BrowsePage",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

export default class BrowsePage extends Component {
  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const { pageContext } = this.props;
    const { amp } = pageContext;
    const tags = this.props.data.tags.nodes;
    const articles = orderBy(
      this.props.data.articles.edges.map((e) => e.node),
      [(n) => (n.siteLinking || {}).importanceRank]
    );
    const states = this.props.data.states.edges.map((e) => e.node);

    const children = (
      <TopLevelLayout showPersistentCta={true}>
        <SEO
          title="Top Bankruptcy Filing Guides and Help Articles"
          description="Links to some of our most popular bankruptcy filing articles and guides organized by topic, city, and state."
          JSONLD={[breadcrumbToJSONLD([{ slug: "/browse/", title: "Top Bankruptcy Filing Articles" }])]}
        />
        <MainHeader trackingPageContext={trackingPageContext} />
        <Directory>
          <div className="directory">
            <article className="directory__content">
              <H2 as="h1">Top Free Bankruptcy Filing Guides and Help Articles</H2>
              <section>
                {tags.map((tag) => (
                  <Fragment key={tag.value}>
                    <H4>
                      <Link to={`/learn/category/${kebabCase(tag.value)}/`}>{tag.label}</Link>
                    </H4>
                    <div className="directory__content__listing">
                      {articles
                        .filter((article) => (article.tags || []).some((articleTag) => articleTag.value === tag.value))
                        .map((article) => (
                          <div key={article.slug}>
                            <A href={`/learn/${article.slug}/`}>{article.title}</A>
                          </div>
                        ))}
                    </div>
                  </Fragment>
                ))}
                <H4>Other Articles</H4>
                <div className="directory__content__listing">
                  {articles
                    .filter((article) => !(article.tags || []).some((articleTag) => articleTag.type === "category"))
                    .map((article) => (
                      <div key={article.slug}>
                        <A href={`/learn/${article.slug}/`}>{article.title}</A>
                      </div>
                    ))}
                </div>
              </section>
              <section>
                <H3>Top Free Bankruptcy Filing Guides by State</H3>
                <div className="directory__content__listing">
                  {states.map((st) => (
                    <div key={st.context.canonicalPath}>
                      <A href={st.context.canonicalPath}>
                        How to File Bankruptcy for Free in {st.context.title.replace("Bankruptcy", "")}
                      </A>
                    </div>
                  ))}
                </div>
              </section>
            </article>
          </div>
        </Directory>
        <MainFooter />
      </TopLevelLayout>
    );

    return amp === true ? <Ampify>{children}</Ampify> : children;
  }
}

const Directory = styled.div`
  .directory {
    margin: 4em 0;
  }
  .directory__content {
    width: 100%;
    max-width: 90%;
    margin: 1em auto;
    display: flex;
    flex-direction: column;
    section {
      margin: 2em 0;
      padding: 2em 0;
      border-top: 1px solid ${(props) => props.theme.colors.white[300]};
    }
    h3,
    h5 {
      margin: 1em 0;
    }
    h4 a {
      color: inherit;
    }
  }
  .directory__content__listing {
    width: 100%;
    margin: 0.5em 0;
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 25%;
      min-height: 40px;
      a {
        text-decoration: underline;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  }
`;

export const pageQuery = graphql`
  query BrowseDirectory {
    articles: allContentfulLearnArticle {
      edges {
        node {
          slug
          title
          tags {
            value
            type
          }
        }
      }
    }
    tags: allContentfulLearnArticleTag(filter: { type: { eq: "category" } }, sort: { fields: [value] }) {
      nodes {
        value
        label
        type
      }
    }
    states: allSitePage(filter: { component: { regex: "/PageTemplate/" }, context: { type: { eq: "statePage" } } }) {
      edges {
        node {
          context {
            title
            canonicalPath
          }
        }
      }
    }
  }
`;
